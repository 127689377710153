import React, { useEffect, useState } from 'react';
import logo from '../images/Logo1.svg';

const Homepage = ({text}) => {
  const [rotatingImages, setRotatingImages] = useState([]);
  const [homeImages, setHomeImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    setRotatingImages(text.homepageRotatingImages.split(',') || []);
    setHomeImages(text.homepageImages.split(',') || []);

    const handleScroll = () => {
      const welcomeOpacity = window.scrollY < 200 ? 1 : 1 - (window.scrollY - 200) / 200;
      document.getElementById('overlay-text').style.opacity = welcomeOpacity;
      document.getElementById('homepage-logo').style.opacity = welcomeOpacity;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % rotatingImages.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [rotatingImages.length]);

  console.log(text.descriptionList);

  return (
    <div>
      <section id="homepage-video">
        <div id="welcome">
          <div id="homepage-logo">
            <img id="logo" src={logo} alt="AntHillSigns" />
          </div>
          <div id="overlay-text">
            <span className="header-norm">
              <h1>{text.homepageWelcome}</h1>
            </span>
            <span className="header-mobile">
              <h1>{text.homepageWelcome}</h1>
            </span>
            <div id="subtext">
              <span className="center-text">
                <h2>{text.homepageSubtext1}</h2>
                <h2>{text.homepageSubtext2}</h2>
              </span>
            </div>
          </div>
        </div>
        {rotatingImages.map((image, index) => (
          <img
            key={index}
            className={`home-image ${index === currentImageIndex ? 'active' : ''}`}
            src={`${process.env.REACT_APP_CLOUD_BUCKET_NAME}${image}`}
            alt={`Rotating Image ${index + 1}`}
          />
        ))}
        <div id="video-box">
          <video width="100%" height="100%" muted autoPlay loop>
            <source src="homevideo_reduced.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
      <article id="homepage-description">
        <div id="description-graphics">
          {homeImages.slice(0, 3).map((image, index) => (
            <img key={index} className="home-images" src={`${process.env.REACT_APP_CLOUD_BUCKET_NAME}${image}`} alt={`Home Image ${index + 1}`} />
          ))}
        </div>
        <section id="description">
          <div id="description-header">
            <h1>{text.descriptionHeader}</h1>
          </div>
          <hr />
          <div id="description-list">
            {text.descriptionList && text.descriptionList.split(',').map((item, index) => (
              <h2 key={index}>• {item}</h2>
            ))}
          </div>
          <div id="description-list-tab">
            <div id="description-column">
              {text.descriptionList && text.descriptionList.split(',').slice(0, 3).map((item, index) => (
                <h2 key={index}>• {item}</h2>
              ))}
            </div>
            <div id="description-column">
              {text.descriptionList && text.descriptionList.split(',').slice(3, 7).map((item, index) => (
                <h2 key={index}>• {item}</h2>
              ))}
            </div> 
            <div id="description-column">
              {text.descriptionList && text.descriptionList.split(',').slice(7).map((item, index) => (
                <h2 key={index}>• {item}</h2>
              ))}
            </div>
          </div>
        </section>
      </article>
    </div>
  );
};

export default Homepage;
