import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Services = ({text}) => {
  const [formData, setFormData] = useState({ name: '', company: '', email: '', phone: '', message: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [images, setImages] = useState(text.servicesImages.split(",") || []);

  const recaptchaRef = React.createRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (token) => {
    if (token) {
      handleSubmit(token);
    }
  };

  const handleSubmit = (token) => {
    axios.post('https://us-central1-anthillsigns.cloudfunctions.net/app/api/contact', { ...formData, 'g-recaptcha-response': token })
      .then(response => {
        setFormData({ name: '', company: '', email: '', phone: '', message: '' });
        setErrorMessage('Thank you, we will contact you as soon as possible!');
      })
      .catch(error => {
        setErrorMessage('Oops, something went wrong. Please try again later.');
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    recaptchaRef.current.execute();
  }; 

  return (
    <div id="permit-design-page">
      <section class="quote-box">
        <div class="quote-header">
            <h1>
                <b>GET A QUOTE</b>
            </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <h5>Full Name</h5>
          <input className="small-box" type="text" name="name" value={formData.name} onChange={handleInputChange} required />
          <h5>Company</h5>
          <input className="small-box" type="text" name="company" value={formData.company} onChange={handleInputChange} required />
          <h5>Email</h5>
          <input className="small-box" type="email" name="email" value={formData.email} onChange={handleInputChange} required />
          <h5>Phone Number</h5>
          <input className="small-box" type="tel" name="phone" value={formData.phone} onChange={handleInputChange} required />
          <h5>How can we help you?</h5>
          <textarea name="message" className="last-box" cols="30" rows="10" value={formData.message} onChange={handleInputChange} required />
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleCaptchaChange}
          />
          <div className="submit-button">
            <button type="submit">Submit</button>
          </div>
          {errorMessage && <div className="center-text" style={{ color: 'red' }}>{errorMessage}</div>}
        </form>
      </section>

      <article className="service-description">
        <h1>{text.servicesHeader}</h1>
        <hr />
        <p>{text.servicesDescription}</p>
        <div className="graphic-bar">
          {images.map((img, index) => (
            <img key={index} className="graphic-bar-img" src={`${process.env.REACT_APP_CLOUD_BUCKET_NAME}${img}`} alt={`Image${index + 1}`} />
          ))}
        </div>
      </article>
    </div>
  );
};

export default Services;

