import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CMS = () => {
  const [textFields, setTextFields] = useState({});
  const [images, setImages] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchTextFields();
    fetchImages();
  }, []);

  const fetchTextFields = async () => {
    try {
      const response = await axios.get('https://us-central1-anthillsigns.cloudfunctions.net/app/api/textFields');
      setTextFields(response.data);
    } catch (error) {
      console.error('Error fetching text fields:', error);
    }
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get('https://us-central1-anthillsigns.cloudfunctions.net/app/api/images');
      setImages(response.data.images);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTextFields({ ...textFields, [name]: value });
  };

  const handleFolderChange = (e) => {
    setSelectedFolder(e.target.files);
  };

  const handleUpload = async () => {
    const files = [];
    const categories = [];
    const filters = [];
    const uniqueFileMap = new Map(); // Map to store unique file names
  
    // Convert FileList to an array
    const selectedFilesArray = Array.from(selectedFolder);
  
    for (let i = 0; i < selectedFilesArray.length; i++) {
      if (selectedFilesArray[i].name === '.DS_Store') {
        continue;
      }
      const parts = selectedFilesArray[i].webkitRelativePath.split('/');
  
      let category;
      let filter;
  
      if (parts.length === 3) {
        category = parts[1];
        filter = "All";
      } else {
        category = parts[1];
        filter = parts[2];
      }
  
      const uniqueName = `${category}-${filter}-${selectedFilesArray[i].name}`;
      files.push({
        name: uniqueName,
        type: selectedFilesArray[i].type,
        category: category,
        filter: filter
      });
  
      uniqueFileMap.set(uniqueName, selectedFilesArray[i]);
  
      console.log(uniqueName);
  
      categories.push(category);
      filters.push(filter);
    }
  
    try {
      setUploading(true);
      setProgress(0);
      const totalFiles = files.length;
      const progressMap = new Map();
  
      // Initialize progress for all files, including skipped files
      files.forEach((file, index) => {
        progressMap.set(index, file.url === null ? 100 : 0);
      });
  
      const response = await axios.post('https://us-central1-anthillsigns.cloudfunctions.net/app/api/generate-upload-urls', { files });
  
      console.log("Uploading files...");
  
      let uploadedFiles = [];
  
      if (response.data.urls) {
        const uploadPromises = response.data.urls.map((signedUrlObj, index) => {
          const uniqueName = signedUrlObj.fileName;
          const file = uniqueFileMap.get(uniqueName); // Find the matching file
  
          // Ensure the file was found
          if (!file) {
            console.error(`File not found: ${uniqueName}`);
            progressMap.set(index, 100);
            const totalProgress = Array.from(progressMap.values()).reduce((acc, progress) => acc + progress, 0);
            setProgress(totalProgress / totalFiles);
            return Promise.resolve(); // Skip this file
          }
  
          if (signedUrlObj.url == null) {
            console.warn(`Skipping file: ${file.name}`);
            uploadedFiles.push({
              fileName: uniqueName,
              category: categories[index],
              filter: filters[index],
            });
            progressMap.set(index, 100);
            const totalProgress = Array.from(progressMap.values()).reduce((acc, progress) => acc + progress, 0);
            setProgress(totalProgress / totalFiles);
            return Promise.resolve(); // Skip this file
          }
  
          return axios.put(signedUrlObj.url, file, {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              progressMap.set(index, percentCompleted);
              const totalProgress = Array.from(progressMap.values()).reduce((acc, progress) => acc + progress, 0);
              setProgress(totalProgress / totalFiles);
            }
          }).then(() => {
            uploadedFiles.push({
              fileName: uniqueName,
              category: categories[index],
              filter: filters[index],
            });
            progressMap.set(index, 100);
            const totalProgress = Array.from(progressMap.values()).reduce((acc, progress) => acc + progress, 0);
            setProgress(totalProgress / totalFiles);
          });
        });
  
        await Promise.all(uploadPromises);
      }
  
      console.log("Uploaded files: ", uploadedFiles);
  
      console.log("Generating thumbnails...");
  
      // Step 2: Inform the server about uploaded files
      const uploadResponse = await axios.post('https://us-central1-anthillsigns.cloudfunctions.net/app/api/generate-thumbnails', {
        files: uploadedFiles.filter(file => file !== undefined) // Filter out any undefined results
      });
  
      console.log("Thumbnail generation response: ", uploadResponse.data);
  
      if (uploadResponse.data.success) {
        alert('Files uploaded successfully!');
      }
    } catch (error) {
      console.error('Error uploading folder: ', error);
      alert('Error uploading folder. Please try again.');
    } finally {
      setUploading(false);
      fetchImages();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://us-central1-anthillsigns.cloudfunctions.net/app/api/textFields', textFields);
      if (response.data.success) {
        alert('Text fields updated successfully!');
      }
    } catch (error) {
      console.error('Error updating text fields:', error);
    }
  };

  const handleImageSelect = (fullPath,thumbnailPath) => {
    setTextFields({ ...textFields, selectedImage: fullPath.split(".com")[2] + "\n\n" + thumbnailPath.split(".com")[2]});
  };

  return (
    <div className="cms">
      <h2>Content Management System</h2>
      <form className="text-fields" onSubmit={handleSubmit}>
        {Object.keys(textFields).map(key => (
          <div key={key} className="form-group">
            <label>{key}</label>
            <textarea
              name={key}
              value={textFields[key]}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        ))}
        <button type="submit">Save Text Fields</button>
      </form>

      <div className="image-upload">
        <h3>Upload and Label Images</h3>
        <input type="file" webkitdirectory="true" directory="true" multiple onChange={handleFolderChange} />
        <button onClick={handleUpload} disabled={uploading}>
          {uploading ? "Please Wait" : "Upload"}
        </button>
        {uploading && <progress value={progress} max="100">{progress}%</progress>}
      </div>
      
      <div className="image-gallery">
        <h3>Image Gallery</h3>
        <div className="image-grid">
          {Array.isArray(images) && images.map((img, index) => (
            img.thumbnailPath != "" &&
            <div className='image-item'>
              <img src={`${img.thumbnailPath}`} alt={img.filter} onClick={() => handleImageSelect(img.fullPath, img.thumbnailPath)}/>
              <p>{img.category} - {img.filter}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CMS;
