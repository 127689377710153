import React from 'react';

const Footer = ({ text }) => {
  return (
    <footer className="copyright">
      <p>{text.copyright}</p>
    </footer>
  );
};

export default Footer;

