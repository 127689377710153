import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';

const Interior = () => {
  const [images, setImages] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filter, setFilter] = useState('all');
  const [expandedImage, setExpandedImage] = useState(null);
  const [resetOpacity, setResetOpacity] = useState(false);
  const [columns, setColumns] = useState([[], [], []]);
  const [navbarToggle, setNavbarToggle] = useState(false);

  const columnsRef = useRef([]);

  useEffect(() => {
    axios.get('https://us-central1-anthillsigns.cloudfunctions.net/app/api/images')
      .then(response => {
        const interiorImages = response.data.images.filter(image => image.category === 'internal' || image.category === 'Internal');
        setImages(interiorImages);
        const validFilters = new Set();
        interiorImages.forEach(image => {
          validFilters.add(image.filter);
        });
        setFilters(Array.from(validFilters) || []);
      })
      .catch(error => console.error('Error fetching images:', error));
  }, []);

  useEffect(() => {
    setResetOpacity(true);

    const fadeInTimer = setTimeout(() => {
      setResetOpacity(false);
    }, 500); // Adjust this delay if needed

    return () => {
      clearTimeout(fadeInTimer);
    };
  }, [filter]);

  useEffect(() => {
    const filterImages = () => {
      if (filter === 'all') {
        return images;
      }
      return images.filter(image => image.filter === filter);
    };

    const distributeImages = (filteredImages) => {
      const columns = [[], [], []];
      filteredImages.forEach((img, index) => {
        columns[index % 3].push(img);
      });
      return columns;
    };

    const filteredImages = filterImages();
    const newColumns = distributeImages(filteredImages);
    setColumns(newColumns);
  }, [filter, images]);

  const syncScroll = (index) => {
    const primaryColumn = columnsRef.current[index];
    const primaryScrollFactor = primaryColumn.scrollTop / (primaryColumn.scrollHeight - primaryColumn.clientHeight);
    columnsRef.current.forEach((col, i) => {
      if (i !== index) {
        col.scrollTop = primaryScrollFactor * (col.scrollHeight - col.clientHeight);
      }
    });
  };

  const throttledSyncScroll = useCallback(
    throttle((index) => {
      syncScroll(index);
    }, 16), // Throttle scroll events to every 50ms
    []
  );

  useEffect(() => {
    const addScrollListeners = () => {
      columnsRef.current.forEach((col, index) => {
        if (col) {
          col.addEventListener('scroll', () => throttledSyncScroll(index));
        }
      });
    };

    const removeScrollListeners = () => {
      columnsRef.current.forEach((col, index) => {
        if (col) {
          col.removeEventListener('scroll', () => throttledSyncScroll(index));
        }
      });
    };

    removeScrollListeners(); // Remove existing listeners
    addScrollListeners(); // Add new listeners

    return () => {
      removeScrollListeners();
    };
  }, [throttledSyncScroll]);

  const expandImg = (img) => {
    setExpandedImage(img.fullPath);
  };

  const closeExpanded = () => {
    setExpandedImage(null);
  };

  const toggleNavbar = () => {
    setNavbarToggle(!navbarToggle);
  };

  return (
    <div className='ext-int-page'>
      {expandedImage && (
        <div className="expand-overlay" onClick={closeExpanded}>
          <img className="overlay-img" src={`${expandedImage}`} alt="Expanded" />
        </div>
      )}
      <article className="ext-int-header">
        <h1>Interior Signs</h1>
      </article>
      <section className="ext-int-signs">
        <div className="portfolio-nav-drawer">
          <button className="portfolio-drawer-button" onClick={toggleNavbar}>
            <div className={`drawer-close ${navbarToggle ? 'hidden' : ''}`}><h4>Filter</h4></div>
            <div className={`drawer-open ${navbarToggle ? '' : 'hidden'}`}><h4>Close</h4></div>
          </button>
          <div className={`portfolio-nav-mbl ${navbarToggle ? 'active' : ''}`}>
            <button className={`portfolio-button ${filter === 'all' ? 'active' : ''}`} onClick={() => { setFilter('all'); toggleNavbar(); }}>
              <h4>Show All</h4>
            </button>
            {filters.map((filterOption, index) => (
              filterOption !== 'All' &&
              <button key={index} className={`portfolio-button ${filter === filterOption ? 'active' : ''}`} onClick={() => { setFilter(filterOption); toggleNavbar(); }}>
                <h4>{filterOption}</h4>
              </button>
            ))}
          </div>
        </div>
        <div className="portfolio-nav">
          <button className={`portfolio-button ${filter === 'all' ? 'active' : ''}`} onClick={() => setFilter('all')}>
            <h4>Show All</h4>
          </button>
          {filters.map((filterOption, index) => (
            filterOption !== 'All' &&
            <button key={index} className={`portfolio-button ${filter === filterOption ? 'active' : ''}`} onClick={() => setFilter(filterOption)}>
              <h4>{filterOption}</h4>
            </button>
          ))}
        </div>
        <div className="portfolio-grid">
          {columns.map((column, columnIndex) => (
            <div className="column" ref={el => columnsRef.current[columnIndex] = el} key={columnIndex}>
              {column.map((img, index) => (
                <button key={index} className="portfolio-item" onClick={() => expandImg(img)}>
                  <img
                    src={`${img.thumbnailPath}`}
                    alt={img.filter}
                    className={`portfolio-image ${!resetOpacity ? 'show' : ''}`}
                    style={{
                      transitionDelay: `${resetOpacity ? '0s' : `${index * 0.1}s`}`
                    }}
                  />
                </button>
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Interior;

// Throttle function to limit the rate of function calls
function throttle(func, limit) {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}

