import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/Logo2.svg';
import navIcon from '../images/nav_icon.png';
import AuthContext from '../contexts/AuthContext';

const Header = ({ text }) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const toggleNavbar = () => {
    setNavbarToggle(!navbarToggle);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setNavbarToggle(false); // Close the navbar after navigation
  };

  return (
    <header>
      <div className="row">
        <button className="navbar-toggle" onClick={toggleNavbar}>
          <img className="navbar-icon" src={navIcon} alt="Navbar Toggle" />
        </button>
        <button className="logo-button" onClick={() => handleNavigation('/')}>
          <img className="logo-text" src={logo} alt="Logo Text" />
        </button>
      </div>
      <nav className={`navbar ${navbarToggle ? 'active' : ''}`}>
        <button className="navbutton" onClick={() => handleNavigation('/')}>
          <h2>{text.headerAboutUs}</h2>
        </button>
        <button className="navbutton" onClick={() => handleNavigation('/services')}>
          <h2>{text.headerServices}</h2>
        </button>
        <button className="navbutton" onClick={() => handleNavigation('/interior')}>
          <h2>{text.headerInteriorSigns}</h2>
        </button>
        <button className="navbutton" onClick={() => handleNavigation('/exterior')}>
          <h2>{text.headerExteriorSigns}</h2>
        </button>
        <button className="navbutton" onClick={() => handleNavigation('/contact')}>
          <h2>{text.headerContactUs}</h2>
        </button>
        {isAuthenticated && (
          <button className="navbutton" onClick={() => handleNavigation('/cms')}>
            <h2>CMS</h2>
          </button>
        )}
      </nav>
    </header>
  );
};

export default Header;




