// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Homepage from './components/Homepage';
import Services from './components/Services';
import Interior from './components/Interior';
import Exterior from './components/Exterior';
import Contact from './components/Contact';
import Footer from './components/Footer';
import CMS from './components/CMS';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import axios from 'axios';
import './App.css';

function App() {
  const [text, setText] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get('https://us-central1-anthillsigns.cloudfunctions.net/app/api/textFields')
      .then(response => {
        setText(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching text fields:', error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Header text={text} />
          <Routes>
            <Route path="/" element={<Homepage text={text} />} />
            <Route path="/services" element={<Services text={text} />} />
            <Route path="/interior" element={<Interior text={text}/>} />
            <Route path="/exterior" element={<Exterior text={text}/>} />
            <Route path="/contact" element={<Contact text={text} />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/cms"
              element={
                <PrivateRoute>
                  <CMS />
                </PrivateRoute>
              }
            />
          </Routes>
          <Footer text={text} />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;



